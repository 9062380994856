import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, Collapse, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CollapseIndicatorText from '../Collapsible/CollapseIndicatorText';

const useStyles = makeStyles((theme) => ({
	listItemRoot: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		margin: '10px 0',
		background: '#fff',
		borderRadius: '5px',
		padding: '10px 20px',
		border: `1px solid ${theme.palette.border.main}`,
		transition: 'background 300ms ease, border-color 300ms ease',
		cursor: 'pointer',
		'& .actionColumn': {
			display: 'none'
		},
		'& .hiddenColumn': {
			visibility: 'hidden'
		},
		'& .onHoverColumn': {
			visibility: 'hidden'
		}
	},
	listItemHover: {
		'&:hover': {
			background: theme.palette.primaryLight.light,
			borderColor: theme.palette.border.hover,
			'& .onHoverColumn': {
				visibility: 'visible'
			},
			'& .actionColumn': {
				display: 'flex'
			},
			'& .hiddenColumn': {
				visibility: 'visible'
			},
			'&.collapsible': {
				cursor: 'pointer'
			},
			'&.collapsible .collapseIndicatorText ': {
				opacity: 1
			}
		}
	},
	cursorNormal: {
		cursor: 'default'
	},
	listItemRootDelete: {
		background: '#BC0A29 linear-gradient(90deg, #DB2242, #BC0A29)',
		color: theme.palette.common.white,
		'&:hover': {
			background: '#BC0A29 linear-gradient(90deg, #DB2242, #BC0A29)',
			borderColor: '#BC0A29'
		}
	},
	listContainer: {
		borderBottom: 'none'
	},
	listItemTextRoot: {
		flex: 'none'
	},
	listItemRootRead: {
		'& p, span': {
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightRegular
		}
	},
	listItemRootNotRead: {
		'& p, span': {
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightMedium
		}
	},
	listItemRootSelected: {
		background: theme.palette.primaryLight.main,
		borderColor: theme.palette.border.hover,
		'& p, span': {
			color: theme.palette.primary.main
		},
		'&:hover': {
			background: theme.palette.primaryLight.dark,
			borderColor: '#8AA1BA'
		},
		'& .hiddenColumn': {
			visibility: 'hidden'
		},
		'& .visibleColumn': {
			visibility: 'visible !important'
		}
	},
	listItemRootNoHover: {
		'&:hover': {
			background: '#fff',
			borderColor: '#F3F3F3'
		}
	}
}));

const TableListItem = ({
	row,
	onClick,
	selected,
	isRead,
	multiSelectEnabled,
	children,
	collapsedChildren,
	setCollapsed,
	disbleHover,
	classNames = '',
	hoverEffect = true,
	disableCollapsedChildrenWrapper = false,
	deleteModeState = false,
	listItemProps = {},
	collapseIndicatorProps = {}
}) => {
	const classes = useStyles();
	const [deleteMode, setDeleteMode] = useState(deleteModeState);

	useEffect(() => {
		if (deleteModeState) return;
		if (deleteMode) setDeleteMode(false);
	}, [row?.id]);

	useEffect(() => {
		if (deleteModeState !== deleteMode) setDeleteMode(deleteModeState);
	}, [deleteModeState]);

	return (
		<ListItem
			dense
			onClick={(e) => {
				if (document.getSelection().type === 'Range') return;
				if (collapsedChildren && setCollapsed) setCollapsed(!row.rowCollapsed);
				if (!onClick) return;
				if (deleteMode) return;
				onClick(e, row);
			}}
			className={clsx('tablelistitem', classNames, {
				[classes.cursorNormal]: !onClick || deleteMode,
				collapsible: collapsedChildren != null
			})}
			classes={{
				root: clsx(classes.listItemRoot, {
					[classes.listItemRootDelete]: deleteMode,
					[classes.listItemRootSelected]: selected,
					[classes.listItemRootNotRead]: isRead === false,
					[classes.listItemRootRead]: isRead === true,
					[classes.listItemHover]: hoverEffect,
					[classes.listItemRootNoHover]: disbleHover
				}),
				container: classes.listContainer
			}}
			{...listItemProps}>
			{typeof children === 'function' ? children({ deleteMode, setDeleteMode }) : children}
			{collapsedChildren && (
				<>
					<Collapse in={Boolean(row.rowCollapsed)}>
						{disableCollapsedChildrenWrapper ? (
							collapsedChildren(row)
						) : (
							<Box
								className='collapsedChildrenWrapper'
								display='flex'
								flexDirection='column'
								ml={multiSelectEnabled ? '119px' : '65px'}
								py={1}>
								{collapsedChildren(row)}
							</Box>
						)}
					</Collapse>
					<CollapseIndicatorText {...collapseIndicatorProps} />
				</>
			)}
		</ListItem>
	);
};

export default TableListItem;
